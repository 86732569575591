import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { Home } from 'react-feather'
import { Link } from 'gatsby'
import { SEO, Layout, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb, Card, Button } from 'react-bootstrap'
import ShowBlank from '../shared/ShowBlank'

class MedicalDepartments extends Component {
  render() {
    const data = this.props.pageContext
    const { favicon } = data.topNav
    const medicalDepartments = data.medicalDepartments
    return (
      <>
        <SEO title="Medical Departments" favicon={favicon} />
        <Layout activeNav="about-us" data={data.topNav}>
          <Container className="my-4 md-mt-10 sm-mt-7">
            <Breadcrumb className="breadcrumb-wrapper mx-0">
              <Breadcrumb.Item href="/">
                <Home className="text-violet mr-2" size={14} />
                Home
            </Breadcrumb.Item>
              <Breadcrumb.Item href="/about-us/company-profile">
                About Us
            </Breadcrumb.Item>
              <Breadcrumb.Item active>Departments</Breadcrumb.Item>
            </Breadcrumb>
            {
              medicalDepartments && medicalDepartments.length > 0
                ? <Row>
                  {medicalDepartments.map((dept, i) =>
                    dept.active
                      ?
                      <Col lg={4} md={4} sm={12} className="my-3" key={i}>
                        <Fade bottom>
                          <Card className="w-100 h-100">
                            <Card.Img
                              variant="top"
                              className="object-fit-cover image-h-md mb-0"
                              src={dept.banner || 'https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/banner.png'}
                              alt={dept.name}
                            />
                            <Card.Body className="d-flex flex-column justify-content-beteween">
                              <Card.Title className="h2 font-weight-bold text-center ellipsis-1 cursor-default" title={dept.name}>{dept.name}</Card.Title>
                              <div className="d-flex justify-content-center w-100 mt-3">
                                <Button variant="link" as={Link} to={"/about-us/medical-department/" + dept.slug} className="text-dark-slate-blue">VIEW DEPARTMENT</Button>
                              </div>
                            </Card.Body>
                          </Card>
                        </Fade>
                      </Col>
                      : null
                  )}
                </Row>
                : <ShowBlank />
            }
          </Container>
          <HMOSlider data={data.accreditedHmos} />
        </Layout>
      </>
    )
  }
}

export default MedicalDepartments 
