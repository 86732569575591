import React from 'react';
import { Container, Image } from 'react-bootstrap'

const ShowBlank = () => {
    return (
        <Container className="d-flex flex-column align-items-center justify-content-center my-5">
            <Image
                src="https://miriam-college.s3-ap-southeast-1.amazonaws.com/assets/empty.svg"
                className="image-md mt-5 mb-3"
            />
            <p className="text-muted mb-5">There are no data available.</p>
        </Container>
    )
}

export default ShowBlank